import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/accounts/[scopeId]/transactions": [3],
		"/accounts/[scopeId]/transactions/multi": [6],
		"/accounts/[scopeId]/transactions/new": [7],
		"/accounts/[scopeId]/transactions/[id]/delete": [4],
		"/accounts/[scopeId]/transactions/[id]/edit": [5],
		"/change-password": [8],
		"/contact": [9],
		"/dashboard": [10],
		"/entities": [11],
		"/entities/new": [60],
		"/entities/[scopeId]/accounts": [13],
		"/entities/[scopeId]/accounts/new": [16],
		"/entities/[scopeId]/accounts/[id]/delete": [14],
		"/entities/[scopeId]/accounts/[id]/edit": [15],
		"/entities/[scopeId]/archive-nodes": [17],
		"/entities/[scopeId]/archive-nodes/new": [20],
		"/entities/[scopeId]/archive-nodes/[id]/delete": [18],
		"/entities/[scopeId]/archive-nodes/[id]/edit": [19],
		"/entities/[scopeId]/bank-accounts": [21],
		"/entities/[scopeId]/bank-accounts/new": [23],
		"/entities/[scopeId]/bank-accounts/[id]/edit": [22],
		"/entities/[scopeId]/banks": [24],
		"/entities/[scopeId]/banks/new": [26],
		"/entities/[scopeId]/banks/[id]/edit": [25],
		"/entities/[scopeId]/blockchains": [27],
		"/entities/[scopeId]/blockchains/new": [29],
		"/entities/[scopeId]/blockchains/[id]/edit": [28],
		"/entities/[scopeId]/centralized-exchange-accounts": [30],
		"/entities/[scopeId]/centralized-exchange-accounts/new": [32],
		"/entities/[scopeId]/centralized-exchange-accounts/[id]/edit": [31],
		"/entities/[scopeId]/centralized-exchanges": [33],
		"/entities/[scopeId]/centralized-exchanges/new": [36],
		"/entities/[scopeId]/centralized-exchanges/[id]/delete": [34],
		"/entities/[scopeId]/centralized-exchanges/[id]/edit": [35],
		"/entities/[scopeId]/counterparts": [37],
		"/entities/[scopeId]/counterparts/new": [40],
		"/entities/[scopeId]/counterparts/[id]/convert": [38],
		"/entities/[scopeId]/counterparts/[id]/edit": [39],
		"/entities/[scopeId]/currencies": [41],
		"/entities/[id]/edit": [12],
		"/entities/[scopeId]/entity-settings": [42],
		"/entities/[scopeId]/reports": [43],
		"/entities/[scopeId]/token-lists": [44],
		"/entities/[scopeId]/token-lists/new": [47],
		"/entities/[scopeId]/token-lists/[id]/delete": [45],
		"/entities/[scopeId]/token-lists/[id]/edit": [46],
		"/entities/[scopeId]/tokens": [48],
		"/entities/[scopeId]/tokens/new": [50],
		"/entities/[scopeId]/tokens/[id]/edit": [49],
		"/entities/[scopeId]/transaction-lines": [51],
		"/entities/[scopeId]/transaction-lines/[id]/delete": [52],
		"/entities/[scopeId]/user-transactions-upload-mappings": [53],
		"/entities/[scopeId]/user-transactions-upload-mappings/new": [55],
		"/entities/[scopeId]/user-transactions-upload-mappings/[id]/edit": [54],
		"/entities/[scopeId]/user-transactions-uploads": [56],
		"/entities/[scopeId]/wallets": [57],
		"/entities/[scopeId]/wallets/new": [59],
		"/entities/[scopeId]/wallets/[id]/edit": [58],
		"/forgot-password": [61],
		"/good-bye": [62],
		"/join": [63],
		"/profile": [64],
		"/reset-password": [65],
		"/sign-in": [66],
		"/sign-up": [67],
		"/team": [68]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';